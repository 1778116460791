import React, { useMemo, ReactNode } from 'react'
import ReactDOM from 'react-dom'
import useActiveWeb3React from './hooks/useActiveWeb3React'
import { BLOCKED_ADDRESSES } from './config/constants'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import App from './App'
import Providers from './Providers'

function Updaters() {
  return (
    <>
      <ListsUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
    </>
  )
}

function Blocklist({ children }: { children: ReactNode }) {
  const { account } = useActiveWeb3React()
  const blocked: boolean = useMemo(() => Boolean(account && BLOCKED_ADDRESSES.indexOf(account) !== -1), [account])
  if (blocked) {
    return <div>Blocked address</div>
  }
  return <>{children}</>
}

ReactDOM.render(
  <React.StrictMode>
    <Blocklist>
      <Providers>
        <Updaters />
        <App />
      </Providers>
    </Blocklist>
    <div className="container" style={{ background:'#011035', width:'100%', maxWidth:"5000px", padding:"25px"}}>
        <div className="row" style={{marginTop:"2%"}}>
          <div className="col-6 col-md-4">
            <img src="/images/156x40.svg" alt="logo"  width="100%" style={{maxWidth:'109px'}} />
          </div>
          <div className="col-6 col-md-4">
            <p style={{color:'white', textAlign:'center'}}>© 2022 AlphaIntelligence, All Rights Reserved</p>
          </div>
          <div className="col-6 col-md-4" style={{textAlign:'center'}}>


          
          <a href="https://twitter.com/@AlphaIntel_so" target="_blank" rel="noreferrer">
            <img src="/images/twitter.png" style={{width:'100%', maxWidth:'26px'}} alt="social" /> 
            </a>
            <a href="https://t.me/AlphaIntelligence" target="_blank" rel="noreferrer">
            <img src="/images/telegram.png" style={{width:'100%', maxWidth:'26px'}} alt="social" /> 
            </a>
            {/* <img src="/images/medium.png" style={{width:'100%', maxWidth:'26px'}} alt="social" /> <img src="/images/reddit.png" style={{width:'100%', maxWidth:'26px'}} alt="social" />  */}
          </div>
        </div>
      </div>
  </React.StrictMode>,
  document.getElementById('root'),
)

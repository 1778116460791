import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Heading, Flex, Image, Text } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import {
  useWeb3React,
  
} from "@web3-react/core";
import axios from 'axios'
import {matchInstagram, matchTiktok, matchYoutube} from './utils'
import ConnectWalletButton from '../../components/ConnectWalletButton'



const NUMBER_OF_POOLS_VISIBLE = 12

const Pools = () => {

  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

  const [loading, setLoading] = React.useState(false)
  const [issubmitted, setIssubmitted] = React.useState(false)
  const [tiktok, setTiktok] = React.useState('')
  const [instagram, setInstagram] = React.useState('')
  const [youtube, setYoutube] = React.useState('')
  const [twitter, setTwitter] = React.useState('')

  const submitInfo = async() => {
      try{
          // https://polar-refuge-71486.herokuapp.com/
          if(account){
              setLoading(true)
              if(tiktok !== '' || youtube !== '' || instagram !== '' || twitter !== ''){


                let t= true;
                let y = true; 
                let i = true;


                if(tiktok !== ''){
                  const a = await matchTiktok(tiktok)
                  if(a === false){
                    t = false; 
                    alert('TikTok link seems invalid!');
                  }
                }

                if(youtube !== ''){
                  const a = await matchYoutube(youtube)
                  if(a === false){
                    y = false; 
                    alert('YouTube link seems invalid!');
                  }
                }

                if(instagram !== ''){
                  const a = await matchInstagram(instagram)
                  if(a === false){
                    i = false; 
                    alert('Instagram link seems invalid!');
                  }
                }
                if(t === true && y === true && i === true){
                  const x = await axios.post('https://polar-refuge-71486.herokuapp.com/recordInfoPoph', {
                      "tiktok":tiktok,
                      "youtube":youtube,
                      "instagram":instagram,
                      "twitter":twitter,
                      "address":account
  
                  })
                  console.log(x)
                }
                setIssubmitted(true)
                  setLoading(false)
              } else {
                  alert('provide at least one social')
                  setLoading(false)
              }

          

          }



      }catch(err){
          alert('an errror occured')
          setLoading(false)
      }

  }


  return (
    <>
      {/* <p style={{color:'#FC7442'}}>LiquidFrame</p> */}
      <div>
        <br /> <br />
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            {/* <Heading as="h1" scale="xxl" color="white" mb="24px" style={{textAlign:'center', fontWeight:"100",   borderRadius:'20px'}}>
             DEX IFRAME 
            </Heading> */}
                  <br /> <br />
              <h2 className="coolgradient" style={{textAlign:'center', fontSize:'37px',  marginTop:"5%", width:'100%', maxWidth:'511px', margin:'0 auto', display:'block', fontWeight:'900'}}>
              Get instant rewards for posting about <a href="https://proofofpaperhands.net/" target="_blank" rel="noreferrer">$POPH</a>
                  </h2>
                  <div className="container">
                  <div className="row">
              <div className="col-12">
                  <div className="card" style={{background:'transparent',border:'0px', padding:'15px'}}>
                      <div className="card-body">
                          <br />
                          <p style={{color:'white', fontSize:'23px'}}>
                          Post content either provided by us or created by you (Bonus credit!) onto the platform of your choice- or all of them.
                          </p>
                          <p style={{color:'white', fontSize:'23px'}}>
                            This can be a tweet with #s or a video about POPH- or even just a meme or clip, just make sure there is Proof of Paper Hands branding!
                          </p>
                          <br /><br />
                          <h2  style={{fontSize:'16px', color:'white', textAlign:'center'}}>
                          Submit the links of your recent posts
                          </h2>
                          <p style={{textAlign:'center', color:'white'}}>
                            and earn $5 per 1k likes or views
                          </p>
                          <br />
                          <div>

<input style={{width:"100%",color:'white', background:'transparent', border:'1px solid #0167FF', borderRadius:'15px', padding:'8px', filter:'drop-shadow(0px 25px 60px rgba(1, 103, 255, 0.09))'}} placeholder="Instagram Link" onChange={(event) => setInstagram(event.target.value)} />
<input style={{width:"100%",color:'white', background:'transparent', border:'1px solid #0167FF', borderRadius:'15px', padding:'8px', filter:'drop-shadow(0px 25px 60px rgba(1, 103, 255, 0.09))', marginTop:'1%'}} placeholder="Tiktok Link" onChange={(event) => setTiktok(event.target.value)} />
<input style={{width:"100%",color:'white', background:'transparent', border:'1px solid #0167FF', borderRadius:'15px', padding:'8px', filter:'drop-shadow(0px 25px 60px rgba(1, 103, 255, 0.09))', marginTop:'1%'}} placeholder="YouTube Link" onChange={(event) => setYoutube(event.target.value)} />
<input style={{width:"100%",color:'white', background:'transparent', border:'1px solid #0167FF', borderRadius:'15px', padding:'8px', marginTop:'1%'}} placeholder="Twitter Link" onChange={(event) => setTwitter(event.target.value)} />

                  <div className="row">
                      <div className="col">
                                        {active ? 
                                        
                                        issubmitted === false ? <button className="btn btn-text" type="button" onClick={submitInfo}  disabled={loading} style={{background:'linear-gradient(141.82deg, #0167FF 18.89%, #FA06FF 79.14%)', border:'0px', borderRadius:'25px', color:'white', width:'100%', maxWidth:'212px', fontSize:'16px', margin:'0 auto', display:'block', marginTop:"3%"}}>
                                        Submit For Review
                                    </button> : <p style={{color:'green', marginTop:"2%", textAlign:"center"}}>Information Submitted Successfully</p>
                                        : 
                                        <> <br />
                                        <ConnectWalletButton width="100%"  />
                                        </>
                                      }
                                 
                                        <p style={{textAlign:'center',color:'white', marginTop:'1%'}}>
                                          Want to learn more? Visit <a href="https://post2earn.app/" className="coolgradient" target="_blank" rel="noreferrer">Post2Earn main page</a>
                                        </p>
                                        </div>
                  </div>
                                    </div>
                        </div>
                        
                </div>
            </div>

        </div>
     

      </div>

          </Flex>
        </Flex>
      </div>
      {/* <Page>
        
      </Page> */}
    </>
  )
}

export default Pools

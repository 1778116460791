export const matchTwitter = async(url) => {
    // let x = url.match(/(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/)
    // alternative
    const x = url.match(/(^|[^'"])(https?:\/\/twitter\.com\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+))/)
    
    return x;
}


export const matchInstagram = async(url) => {
    if(url.includes('/p/')){
        const newUrls = url.split('/p/')
        return(newUrls[1])
    } 
        return false
}

export const matchTiktok = async(url) => {
    if(url.includes('/video/')){
        const newUrls = url.split('/video/')
        return(newUrls[1])
    } 
        return false
}

export const matchYoutube = async(url) => {
    if(url.includes('watch?v=')){
        const newUrls = url.split('watch?v=')
        return(newUrls[1])
    } 
        return false

}

import React from 'react'
import styled from 'styled-components'
import PageSection from 'components/PageSection'
import { useWeb3React } from '@web3-react/core'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import ImageScroller from 'react-image-scroller';


const Home: React.FC = () => {
  const { theme } = useTheme()
  const { account } = useWeb3React()

  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }

  return (
    <>
      <div className="container">

        <div className="row">
          <div className="col-12">
            <h4 className="coolgradient2" style={{textAlign:'center', marginTop:'9%', fontSize:'15px'}}>$AI , Paving the way for the future of technology</h4>
          </div>
          <div className="col-12">
            <h1 className="coolgradient2" style={{textAlign:'center', fontSize:'36px', fontWeight:'bold', marginTop:'2%'}}>ALPHA INTELLIGENCE INTRODUCTION</h1>
          </div>
          <div className="col-12">
            <p style={{textAlign:'center', fontSize:'18px', color:'white', width:'100%', maxWidth:'638px', margin:'0 auto', display:'block', marginTop:'2%'}}>
            A holding in the new $AI (Alpha Intelligence) marks a new phase and new journey for $Alpha.  Through $AI Alpha Labz enters the trend of AI as innovators of DeFi in this field.
            See the new &quot;AI&quot; Zone by Alpha.Intelligence where project owners and crypto fanatics can do a variety of things just from holding $AI
            </p>
          </div>
          <div className="col-6">
            <br />
            <button type="button" className="btn btn-primary" style={{float:'right', borderRadius:'15px', background:'linear-gradient(95.79deg, #0167FF 4.35%, #FA06FF 100%)', border:'0px'}}>
              Read Whitepaper
            </button>
          </div>
        
          <div className="col-6">
          <br />
          <a href="https://t.me/AlphaLabzBSC" target="_blank" rel="noreferrer">
            {/* <button type="button" className="btn btn-text" style={{color:'white', textDecorationLine:'underline'}}>
            Join our discussions group!
            </button> */}
                          <button type="button" className="btn btn-primary" style={{float:'left', borderRadius:'15px', background:'linear-gradient(95.79deg, #0167FF 4.35%, #FA06FF 100%)', border:'0px'}}>
              Join our discussions group!
            </button>
            </a>
          </div>
          <div className="col-12 col-md-4">
            <a href="/ai">
              <img src="/images/card10.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12 col-md-4">
            <a href="https://t.me/AlphaIntelligence_Bot" target="_blank" rel="noreferrer">
              <img src="/images/card12.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12 col-md-4">
          <a href="https://t.me/AlphaIntelligence_Bot" target="_blank" rel="noreferrer">
              <img src="/images/card11.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12">
            <h4 className="coolgradient2" style={{textAlign:'center', marginTop:'3%', fontSize:'23px', fontWeight:'bold'}}>        Alpha Intelligence 


              <p>      
              Web3 News Pod                </p>
            </h4>
          </div>
          <a href="https://medium.com/@givan812/why-is-there-a-current-surge-in-investment-into-ai-oriented-crypto-projects-and-how-could-ai-dd4e852e828d" target="_blank" rel="noreferrer">
          <div className='col-12' style={{textAlign:'center', marginTop:'2rem'}}>
            <h4 style={{color:'white', fontWeight:'bold'}}>
            Why is there a current surge in investment into AI-oriented crypto projects and how could AI integrate into the blockchain?
            </h4>
            <p style={{color:'#B9B9D3'}}>
            There are several factors at play with the surge in investment into AI-oriented crypto projects:
            </p>
          </div>
          </a>

          <a href="https://medium.com/@AlphaIntelligenceAI/the-latest-condensed-news-in-the-binance-smart-chain-network-summarised-with-links-to-the-full-6edcf8952619" target="_blank" rel="noreferrer">
          <div className='col-12' style={{textAlign:'center', marginTop:'2rem'}}>
            <h4 style={{color:'white', fontWeight:'bold'}}>
            14th February 2023: The latest daily condensed news in the Binance Smart Chain Network summarised with links to the full articles for the desire to explore further into a specified topic
</h4>
            <p style={{color:'#B9B9D3'}}>
            Binance Smart Chain sets record after 14.7 million daily transactions in one day            </p>
          </div>
          </a>



        <br /><br />










          </div>
      </div>
      <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />

    </>
  )
}

export default Home

import React from 'react'
import styled from 'styled-components'
import PageSection from 'components/PageSection'
import { useWeb3React } from '@web3-react/core'
import useTheme from 'hooks/useTheme'
import Container from 'components/Layout/Container'
import ImageScroller from 'react-image-scroller';


const Home: React.FC = () => {
  const { theme } = useTheme()
  const { account } = useWeb3React()

  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px' }

  return (
    <>
      <div className="container">

        <div className="row">
          <div className="col-12">
            {/* <p style={{color:'white', background:'red', padding:'10px', fontWeight:'bold', textAlign:'center'}}>
            $AI , Paving the way for the future of technology
            </p> */}
            <h4 className="coolgradient2" style={{textAlign:'center', marginTop:'9%', fontSize:'15px'}}>$AI , Paving the way for the future of technology</h4>

          </div>
          <div className="col-12">
            <h1 className="coolgradient2" style={{textAlign:'center', fontSize:'36px', fontWeight:'bold', marginTop:'2%'}}>
            ALPHA INTELLIGENCE INTRODUCTION
            </h1>
          </div>
          <div className="col-12">
            <p style={{textAlign:'center', fontSize:'18px', color:'white', width:'100%', maxWidth:'638px', margin:'0 auto', display:'block', marginTop:'2%'}}>
            A holding in the new $AI (Alpha Intelligence) marks a new phase and new journey for $Alpha.  Through $AI Alpha Labz enters the trend of AI as innovators of DeFi in this field.
            See the new &quot;AI&quot; Zone by Alpha.Intelligence where project owners and crypto fanatics can do a variety of things just from holding $AI            </p>
          </div>
          <div className="col-6">
            <br />
            <button type="button" className="btn btn-primary" style={{float:'right', borderRadius:'15px', background:'linear-gradient(95.79deg, #0167FF 4.35%, #FA06FF 100%)', border:'0px'}}>
              Read Whitepaper
            </button>
          </div>
        
          <div className="col-6">
          <br />
          <a href="https://t.me/AlphaLabzBSC" target="_blank" rel="noreferrer">
            {/* <button type="button" className="btn btn-text" style={{color:'white', textDecorationLine:'underline'}}>
            Join our discussions group!
            </button> */}
              <button type="button" className="btn btn-primary" style={{float:'left', borderRadius:'15px', background:'linear-gradient(95.79deg, #0167FF 4.35%, #FA06FF 100%)', border:'0px'}}>
              Join our discussions group!
            </button>
            </a>
          </div>
          <div className='col-12'>
            <div className='card' style={{textAlign:'center', background:'#02023A', border:'1px solid purple', borderRadius:'30px', padding:'15px', maxWidth:'500px', fontSize:'16px', margin:'0 auto', color:'white', marginTop:'5%'}}>
              <p>
              Welcome to the <a href="/ai-zone" className='coolgradient2' style={{textDecoration:'underline'}}>AI Zone!</a> With a holding in $AI, Gain access to tools to create project logos, validate project ideas, recieve help in naming and branding all the way to creating your own website through AI!
              </p>
              <a href="/ai-zone"  style={{textDecoration:'underline'}}>
              <button type="button" className="btn btn-text" style={{marginTop:'1rem', color:'white', borderRadius:'15px', background:'linear-gradient(95.79deg, #0167FF 4.35%, #FA06FF 100%)', border:'0px'}}>
              Visit Page
            </button>
            </a>
            </div>
          </div>
          <div className="col-12">
            <h4 className="coolgradient2" style={{textAlign:'center', marginTop:'9%', fontSize:'23px', fontWeight:'bold'}}>          Daily Web3 tools for
              <p>      
                $AI holders
                </p>
            </h4>
          </div>
          <div className="col-12 col-md-4">
            <a href="/pools">
              <img src="/images/card1.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12 col-md-4">
            <a href="/Post2Earn">
              <img src="/images/card2.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12 col-md-4">
            <a href="/swap">
              <img src="/images/card3.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12">
            <h4 className="coolgradient2" style={{textAlign:'center', marginTop:'10%', fontSize:'23px', fontWeight:'bold'}}>          Alpha’s Development Suite

              <p>      
              for Project owners
                </p>
            </h4>
          </div>
          <div className="col-12 col-md-4">
            <a href="/entry-staking">
              <img src="/images/card4.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12 col-md-4">
            <a href="https://t.me/topgentledev" target="_blank" rel="noreferrer">
              <img src="/images/card5.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12 col-md-4">
            <a href="/token-creator">
              <img src="/images/card6.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12 col-md-4">
            <a href="/">
              <img src="/images/card7.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12 col-md-4">
            <a href="/make-a-dex">
              <img src="/images/card8.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>
          <div className="col-12 col-md-4">
            <a href="https://mintstarter.app" target="_blank" rel="noreferrer">
              <img src="/images/card9.svg" alt="card" style={{width:'100%'}} />
            </a>
          </div>


          <div className="col-12">
          <h4 className="coolgradient2" style={{textAlign:'center', marginTop:'6%', fontSize:'23px', fontWeight:'bold'}}>          The Team
              </h4>
          </div>
        </div>
        <br /><br />
        <div className="row">
          
          <div className="col-12 col-md-1" />
        <div className="col-4 col-md-2">
              <img src="/images/avatar1.svg" alt="card" style={{width:'100%', maxWidth:'118px', margin:'0 auto', display:'block'}} />
              <h4 style={{textAlign:'center', color:'white'}}>
              DuraCryptoMax
              </h4>
              <h4 style={{textAlign:'center', color:'grey'}}>
              CoFounder
              </h4>
              <div className="row">
                <div className="col-6">
                  {/* <a href="https://t.me/DuraCryptoMax" target="_blank" rel="noreferrer">
                    <img src="/images/tw.svg" width="27px" alt="twitter" style={{float:'right'}} />
                  </a> */}
                </div>
                <div className="col-12">
                  <a href="https://t.me/DuraCryptoMax" target="_blank" rel="noreferrer" >
                    <img src="/images/tg.svg" width="27px" alt="twitter" style={{margin:'0 auto', display:'block'}} />
                  </a>
                </div>
              </div>
          </div>

          <div className="col-4 col-md-2">
              <img src="/images/avatar2.svg" alt="card" style={{width:'100%', maxWidth:'118px', margin:'0 auto', display:'block'}} />
              <h4 style={{textAlign:'center', color:'white'}}>
              TopGentleDev
              </h4>
              <h4 style={{textAlign:'center', color:'grey'}}>
              CoFounder
              </h4>
              <div className="row">
                <div className="col">
                  {/* <a href="https://t.me/TopGENTLEDEV" target="_blank" rel="noreferrer">
                    <img src="/images/tw.svg" width="27px" alt="twitter" style={{float:'right'}} />
                  </a> */}
                </div>
                <div className="col-12">
                  <a href="https://t.me/TopGENTLEDEV" target="_blank" rel="noreferrer">
                    <img src="/images/tg.svg" width="27px" alt="twitter" style={{margin:'0 auto', display:'block'}} />
                  </a>
                </div>
              </div>
          </div>

          <div className="col-4 col-md-2">
              <img src="/images/avatar3.svg" alt="card" style={{width:'100%', maxWidth:'118px', margin:'0 auto', display:'block'}} />
              <h4 style={{textAlign:'center', color:'white'}}>
              NEO
              </h4>
              <h4 style={{textAlign:'center', color:'grey'}}>
              Web3 Dev
              </h4>
              <div className="row">
                <div className="col">
                  {/* <a href="https://t.me/TakeTheRed" target="_blank" rel="noreferrer">
                    <img src="/images/tw.svg" width="27px" alt="twitter" style={{float:'right'}} />
                  </a> */}
                </div>
                <div className="col-12">
                  <a href="https://t.me/TakeTheRed" target="_blank" rel="noreferrer">
                    <img src="/images/tg.svg" width="27px" alt="twitter" style={{margin:'0 auto', display:'block'}} />
                  </a>
                </div>
              </div>
          </div>

          <div className="col-4 col-md-2">
              <img src="/images/avatar4.svg" alt="card" style={{width:'100%', maxWidth:'118px', margin:'0 auto', display:'block'}} />
              <h4 style={{textAlign:'center', color:'white'}}>
              Trader
              </h4>
              <h4 style={{textAlign:'center', color:'grey'}}>
              Host
              </h4>
              <div className="row">
                <div className="col">
                  {/* <a href="https://t.me/TradarDAO" target="_blank" rel="noreferrer">
                    <img src="/images/tw.svg" width="27px" alt="twitter" style={{float:'right'}} />
                  </a> */}
                </div>
                <div className="col-12">
                  <a href="https://t.me/TradarDAO" target="_blank" rel="noreferrer">
                    <img src="/images/tg.svg" width="27px" alt="twitter" style={{margin:'0 auto', display:'block'}} />
                  </a>
                </div>
              </div>
          </div>

          <div className="col-4 col-md-2">
              <img src="/images/avatar5.svg" alt="card" style={{width:'100%', maxWidth:'118px', margin:'0 auto', display:'block'}} />
              <h4 style={{textAlign:'center', color:'white'}}>
              The Ayrrad
              </h4>
              <h4 style={{textAlign:'center', color:'grey'}}>
              Web Designer
              </h4>
              <div className="row">
                <div className="col">
                  {/* <a href="https://t.me/TheAyrrad" target="_blank" rel="noreferrer">
                    <img src="/images/tw.svg" width="27px" alt="twitter" style={{float:'right'}} />
                  </a> */}
                </div>
                <div className="col-12">
                  <a href="https://t.me/TheAyrrad" target="_blank" rel="noreferrer">
                    <img src="/images/tg.svg" width="27px" alt="twitter" style={{margin:'0 auto', display:'block'}} />
                  </a>
                </div>
              </div>
          </div>

          <div className="col-12">
              <h4 className="coolgradient2" style={{textAlign:'center', marginTop:'8%', fontSize:'23px', fontWeight:'bold'}}>          Partners
              </h4>
          </div>
          <div className="col-12 col-md-12" style={{overflow: "hidden", display: "flex", justifyContent:"space-around", marginTop:'2%'}}>
              
              <a href="https://pinksale.finance" target="_blank" rel="noreferrer">
              <img src="/images/partner1.svg" style={{width:'100%',maxWidth:'200px'}} alt="First" />
              </a>
              <a href="https://interfi.network/" target="_blank" rel="noreferrer">
              <img src="/images/partner2.svg" style={{width:'100%',maxWidth:'171px'}} alt="Second" />
              </a>
              <a href="/" target="_blank" rel="noreferrer">
              <img src="/images/partner3.svg" style={{width:'100%',maxWidth:'87px'}} alt="Third" />
              </a>
              <a href="https://t.me/tokerrportal" target="_blank" rel="noreferrer">
              <img src="/images/partner4.svg" style={{width:'100%',maxWidth:'105px'}} alt="Third" />
              </a>
              <a href="https://t.me/Maverick_Marketing" target="_blank" rel="noreferrer">
              <img src="/images/partner5.svg" style={{width:'100%',maxWidth:'120px'}} alt="Third" />
              </a>
              <a href="https://forkswap.org" target="_blank" rel="noreferrer" >
              <img src="/images/partner6.svg" style={{width:'100%',maxWidth:'58px'}} alt="six" />
              </a>
          </div>

          <div className="col-12">
              <h4 className="coolgradient2" style={{textAlign:'center', marginTop:'8%', fontSize:'23px', fontWeight:'bold'}}>          Marketers
              </h4>
          </div>

          <div className="col-12 col-md-12" style={{overflow: "hidden", display: "flex", justifyContent:"space-around", marginTop:'2%'}}>
              
              <a href="/" target="_blank" rel="noreferrer">
              <img src="/images/marketer1.svg" style={{width:'100%',maxWidth:'118px'}} alt="First" />
              </a>
              <a href="/" target="_blank" rel="noreferrer">
              <img src="/images/marketer2.svg" style={{width:'100%',maxWidth:'118px'}} alt="Second" />
              </a>
              <a href="/" target="_blank" rel="noreferrer">
              <img src="/images/marketer3.svg" style={{width:'100%',maxWidth:'118px'}} alt="Third" />
              </a>
              <a href="/" target="_blank" rel="noreferrer">
              <img src="/images/marketer4.svg" style={{width:'100%',maxWidth:'118px'}} alt="Third" />
              </a>
              <a href="/" target="_blank" rel="noreferrer">
              <img src="/images/marketer5.svg" style={{width:'100%',maxWidth:'118px'}} alt="Third" />
              </a>
              <a href="/" target="_blank" rel="noreferrer" >
              <img src="/images/marketer6.svg" style={{width:'100%',maxWidth:'118px'}} alt="six" />
              </a>
              <a href="/" target="_blank" rel="noreferrer" >
              <img src="/images/marketer7.svg" style={{width:'100%',maxWidth:'118px'}} alt="six" />
              </a>
          </div>
          </div>
      </div>
      <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />
      <br /><br />

    </>
  )
}

export default Home

import React from 'react'
import styled from 'styled-components'
import { Heading, Text, Card, CardHeader, CardBody } from '@pancakeswap/uikit'
import UnlockButton from 'components/ConnectWalletButton'
import Page from 'components/Layout/Page'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import ReferralLink from './components/ReferralLink'
import TotalReferralCount from './components/TotalReferralCount'

const Con = styled.div`
    align-items: center;
    justify-content: center;
    text-align: center;
`

const StyledCard = styled(Card)`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border:1px solid #0167FF;
`

const Actions = styled.div`
  margin-top: 24px;
`

const StyleHeading = styled(Heading)`
    margin-top: 15px
`

const Referral: React.FC = () => {
    const { account } = useActiveWeb3React()

    return (
        <Page>
                  <h5 className="coolgradient2" style={{textAlign:'center', fontWeight:'bold', fontSize:'2rem', marginTop:'5%'}}>
                  Generate your own referral link, share and earn up to 1% of your affiliates participation in swaps forever!
                </h5>
          <Con>
              <br /> <br /> <br /> <br />
              {/* <Heading size="xl" mb="24px" style={{color:'white'}}>Crypto Academy Referral Program</Heading>  */}
              <StyledCard>
                  {/* <CardHeader style={{background:'linear-gradient(180deg, #009EE0 0.02%, #0395DB 18.06%, #0A7DCD 47.25%, #1557B7 83.75%, #1B43AC 100.06%)'}}>
                      <Heading size="md">Generate your own referral link, share and earn up to 1% of your affiliates participation in swaps forever!</Heading>
                  </CardHeader> */}
                  <CardBody style={{background:'#02023A'}}>
                      <Actions>
                          {account ? ( 
                              <div>
                                  <ReferralLink />
                                  <TotalReferralCount />
                              </div>     
                          ) : (
                              <div>
                                                                    <StyleHeading size="md">Unlock wallet to get your unique referral link</StyleHeading>    
                                                                    <br />
                                  <UnlockButton />    
                              </div>   
                          )}
                      </Actions>
                      {/* 0 - 0.5% of supply
0.5% - 1%
1%+ */}
                  </CardBody>      
              </StyledCard>
          </Con>    
        </Page>
    )

}

export default Referral
/* eslint-disable */
import * as React from "react";
import * as ReactDOM from "react-dom";
import {
    useWeb3React,
  } from "@web3-react/core";
  import Web3 from 'web3'
import ABI from './token2_abi.json'
import BYTECODE from './token2_bytecode.json'
import ConnectWalletButton from '../../components/ConnectWalletButton'


function TokenCreator() {
  

  const [name, setName] = React.useState('')
  const [symbol, setSymbol] = React.useState('')
  const [decimals, setDecimals] = React.useState('')
  const [supply, setSupply] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [isdeployed, setIsdeployed] = React.useState(false)
  const [tokenaddress, setTokenaddress] = React.useState('')

  
  const context = useWeb3React();
  const {
    library,
    chainId,
    account,
    active,
  } = context;



  function toFixed(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
      }
    }
    return x;
  }
  

  const deployToken = async() => {
      try{
        setLoading(true)
        if(account && (chainId === 56)){
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(ABI);
            const amount = supply * 10 ** decimals;
            const amountToPay = '200000000000000000'
            console.log(toFixed(amount))
            const x = await contract.deploy({
                data: BYTECODE.object,
                arguments: [name.toString(), symbol.toString(), decimals.toString(), toFixed(amount)]
                  }).send({
                    from: account,
                    value: amountToPay
                  })
            setLoading(false)
            setIsdeployed(true)
            setTokenaddress(x._address)
      }
      }catch(err){
        alert("something went wrong deploying your token")
        console.log(err)
        setLoading(false)
      }

  }

  // const newF = async() => {
  //   const web3 = new Web3(new Web3.providers.HttpProvider('https://test2-rpc.safuux.com'));
  //   var block = await web3.eth.getBlock("latest");
  //   console.log("gasLimit: " + block.gasLimit, block);

  // }

  return (
    <div className="container">
      {/* <button className="btn btn-primary" onClick={newF} type="button">click  e </button> */}
    <div className="row" style={{marginTop:'10%', marginBottom:'5%'}}>
      <div className="col-12">
      <h5 className="coolgradient2" style={{textAlign:'center', fontWeight:'bold', fontSize:'2rem'}}>
                Create A Token
                </h5>
      <p style={{textAlign:'center', color:'white', width:'100%', maxWidth:'540px', margin:'0 auto', display:'block', marginTop:'2%'}}>
                We bring for you the best BEP20/ERC20 token creator on the market.
With our automated factory you will be able to create your own token on blockchain at the speed of the click of a button.                </p>
      </div>

        <div className="card" style={{width:"100%", maxWidth:'566px', margin:'0 auto', display:'block', color:'white', background:'#02023A', borderRadius:'22px', padding:'10px', marginTop:'3%', boxShadow:'0 5.40728px 10.8146px rgb(71, 66, 255)'}}>
            <div className="card-body" style={{padding:'15px'}}>

                <h5 style={{textAlign:'left', color:'white', fontWeight:'bold'}}>
                    Token Specs
                </h5>
                <ul style={{padding:'0'}} className="customul">
                    <li className="customli">Fully BEP20/ERC20 compliant</li>
                    <li className="customli">Super Secure</li>
                    <li className="customli">No MINT function</li>
                    <li className="customli">Decentralized</li>
                    <li className="customli">Alien-tech for releasing tokens (see below)</li>
                </ul>
                <h5 style={{textAlign:'left', color:'white', fontWeight:'bold', marginTop:'2%'}}>
                    Service Price
                </h5>
                <ul style={{padding:'0'}}>
                    <li className="customul">1.2 BNB</li>
                </ul>
                <div style={{marginTop:'2%'}}>
                <div className="mb-3">
                    <label for="exampleFormControlInput1" className="form-label">Token Name</label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" style={{ color:'white', background:'transparent', border:'1px solid #0167FF', borderRadius:'15px'}} placeholder="BlockSwap Finance" value={name} onChange={(event) => setName(event.target.value)} />
                </div>
                <div className="mb-3">
                    <label for="exampleFormControlInput1" className="form-label">Token Symbol (No spaces)</label>
                    <input type="text" className="form-control" id="exampleFormControlInput1" style={{ color:'white', background:'transparent', border:'1px solid #0167FF', borderRadius:'15px'}} placeholder="BlockSwap" value={symbol} onChange={(event) => setSymbol(event.target.value)} />
                </div>
                <div className="mb-3">
                    <label for="exampleFormControlInput1" className="form-label">Decimals</label>
                    <input type="number" className="form-control" id="exampleFormControlInput1" style={{ color:'white', background:'transparent', border:'1px solid #0167FF', borderRadius:'15px'}} placeholder="18(recommended)"  value={decimals} onChange={(event) => setDecimals(event.target.value)} />
                </div>
                <div className="mb-3">
                    <label for="exampleFormControlInput1" className="form-label">Total Supply</label>
                    <input type="number" className="form-control" id="exampleFormControlInput1" style={{ color:'white', background:'transparent', border:'1px solid #0167FF', borderRadius:'15px'}}  placeholder="100000000" value={supply} onChange={(event) => setSupply(event.target.value)} />
                </div>
                {account && active ? <div> 
                    <button className="btn btn-outline-primary" onClick={deployToken} disabled={loading}  style={{border:'0px',borderRadius:'25px', background:'linear-gradient(95.79deg, #0167FF 4.35%, #FA06FF 100%)',color:'white', width:'100%'}} type="button"> {loading ? <div className="spinner-border text-light" role="status">
                    </div> : <><span> Deploy on</span> {chainId === 56 ? "Binance Smart Chain" : 'undefined network'} </>} </button>   
                 </div> : <ConnectWalletButton width="100%" />}
                </div>
                <div>
                    {isdeployed ? <div className="alert alert-primary" style={{marginTop:'3%'}} role="alert">
                        <p>Your token has been deployed at <span style={{fontWeight:'bold'}}>{tokenaddress}</span> . Save this address for reference purposes.</p>
                        <p> <a href={`https://bscscan.com/address/${tokenaddress}`} target="_blank" rel="noreferrer">View on BscScan</a></p>
                         </div> : null}
                </div>
        
            </div>        

        </div>
        <div style={{marginTop:'3%'}}>
          <h4 style={{color:'white'}}>Note </h4>
            <p style={{color:'white'}}>
                9/10 token deployment services release tokens via a smart contract. We&apos;ve engineered the entire system to provide you with an industry-leading token deployer done directly from your wallet. 
                There are hundreds of benefits when doing that, including <span style={{fontWeight:'bold'}}>ease of verifying token on BscScan/Etherscan</span>, <span style={{fontWeight:'bold'}}>improved perceived professionalism of your project / token </span>, 
                and <span style={{fontWeight:'bold'}}>a closer token to how professional developers release them.</span>
                </p>

                <p style={{color:'white'}}>
                For more complex contracts to be built from scratch from our in house developer, message <a href="https://t.me/AlphaLabzBSC" style={{color:'cornflowerblue'}} target="_blank" rel="noreferrer"> @AlphaLabzBSC </a> with the key word ‘ Complex Contract ‘
                </p>
            </div>

    </div>
    </div>

  ); 
}

export default TokenCreator;
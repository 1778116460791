import React from 'react'
import { Heading, Text } from '@pancakeswap/uikit'
import styled from 'styled-components'

const StyleCon = styled.div`
    margin-top: 32px
`

const StyleText = styled(Text)`
    margin-top: 5px;
`


const TotalReferralCount = () => {
    return (
        <StyleCon>
            {/* <Heading size="md">Total Referrals</Heading>
            <StyleText>{referralCount}</StyleText> */}
        </StyleCon>
    )
}

export default TotalReferralCount
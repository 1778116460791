/* eslint-disable */


import * as React from "react";
import * as ReactDOM from "react-dom";
import {
    useWeb3React,
  } from "@web3-react/core";
import Web3 from 'web3'

import multicallabi from '../../config/abi/Multicall.json'
import { Interface } from '@ethersproject/abi'
import stakingabi from './staking_abi.json'
import TOKENABI from './token_abi.json'
import BigNumber from 'bignumber.js'
import useGetPriceDataCustom from './price'
import {
    useParams
  } from "react-router";

function NFTSaleComponent(props) {
  
    // {address}


  const context = useWeb3React();
  const {
    library,
    chainId,
    account,
    active,
  } = context;


  const [currentBlock, setCurrentBlock] = React.useState('')
  const [startBlock, setStartBlock] = React.useState('')
  const [endBlock, setEndBlock] = React.useState('')
  const [stakesymbol, setStakesymbol] = React.useState('')
  const [earnsymbol, setEarnsymbol] = React.useState('')
  const [stakedecimal, setStakedecimal] = React.useState(18)
  const [earndecimal, setEarndecimal] = React.useState(18)
  const [stakeAddress, setStakeAddress] = React.useState('')
  const [earnAddress, setEarnAddress] = React.useState('')
  const [tvl, setTvl] = React.useState('')
  const [apr, setApr] = React.useState('')
  const [amountstaked, setAmountstaked] = React.useState('')
  const [rewardearned, setRewardearned] = React.useState('')
  const [stakenow, setStakenow] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [approval, setApproval] = React.useState(false)

  let { address } = useParams();


  const MyF = async (currecy) => {
    const x = await useGetPriceDataCustom(currecy)
    return x;
  
  }

  const approveAction = async() => {
    try{
        if(account && active){
            setLoading(true)
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(TOKENABI, stakeAddress);
            await contract.methods.approve(address, '115792089237316195423570985008687907853269984665640564039457584007913129639935').send({
                from: account
            })
            setLoading(false)
            window.location.reload(true)
        }

    }catch(err){
        setLoading(false)
        alert("Something went wrong");
    }
  }

  const stakeAction = async() => {
      try{
        if(account && active){
            setLoading(true)
            const web3 = new Web3(library.provider)
            const contract = new web3.eth.Contract(stakingabi, address);
            const amountToStake = stakenow * 10 ** stakedecimal; 
            await contract.methods.deposit(amountToStake.toString()).send({
                from: account
            })
            setLoading(false)
            window.location.reload(true)
    
          } else {
            setLoading(false)
            alert("Connect to Web3");
          }
      }catch(err){
          console.log(err)
        setLoading(false)
        alert("Something went wrong");
    }
      }

      const unstakeAction = async() => {
        try{
          if(account && active){
              setLoading(true)
              const web3 = new Web3(library.provider)
              const contract = new web3.eth.Contract(stakingabi, address);
              const amountToUnstake = await contract.methods.userInfo(account).call()
              await contract.methods.withdraw(amountToUnstake.amount.toString()).send({
                  from: account
              })
              setLoading(false)
              window.location.reload(true)
      
            } else {
              setLoading(false)
              alert("Connect to Web3");
            }
        }catch(err){
            console.log(err)
          setLoading(false)
          alert("Something went wrong");
      }
        }


  React.useEffect(async() => {

        const calls = [
            {
                address: address,
                name: 'stakedToken',
                params: [],
            },
            {
                address: address,
                name: 'rewardToken',
                params: [],
            },
            {
                address: address,
                name: 'rewardPerBlock',
                params: [],
            },
            {
                address: address,
                name: 'bonusEndBlock',
                params: [],
            },
            {
                address: address,
                name: 'startBlock',
                params: [],
            },
            
        ]
        const multicallResults = await ethMulticall(stakingabi, calls)
        console.log(multicallResults)
        setStakeAddress(multicallResults[0].toString())
        setEarnAddress(multicallResults[1].toString())

        const calls2 = [
            {
                address: multicallResults[0].toString(),
                name: 'symbol',
                params:[],
            },
            {
                address: multicallResults[1].toString(),
                name: 'symbol',
                params:[],
            },
            {
                address: multicallResults[0].toString(),
                name: 'decimals',
                params:[],
            },
            {
                address: multicallResults[1].toString(),
                name: 'decimals',
                params:[],
            },
            {
                address: multicallResults[0].toString(),
                name: 'balanceOf',
                params:[address],
            },
            {
                address: multicallResults[1].toString(),
                name: 'balanceOf',
                params:[address],
            },
        ]
        const multicallResults2 = await ethMulticall(TOKENABI, calls2)
        console.log(multicallResults2)
        const myProvider = new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
        const web3 = new Web3(myProvider)
        const currentBlock = await web3.eth.getBlockNumber()
        setCurrentBlock(currentBlock)
        setStartBlock(multicallResults[4].toString())
        setEndBlock(multicallResults[3].toString())
        setStakesymbol(multicallResults2[0].toString())
        setEarnsymbol(multicallResults2[1].toString())
        setStakedecimal(multicallResults2[2].toString())
        setEarndecimal(multicallResults2[3].toString())
        const TVL = multicallResults2[5].toString() / 10 ** multicallResults2[3].toString()
        setTvl(parseFloat(TVL).toFixed(2).toString())
        const stakeTokenPrice = await MyF(multicallResults[0].toString())
        const earnTokenPrice = await MyF(multicallResults[1].toString())
        console.log(stakeTokenPrice, earnTokenPrice)
        const actualRewarPerBlock = multicallResults[2].toString() / 10 ** multicallResults2[3]
        const APR = await getPoolApr(stakeTokenPrice, earnTokenPrice, multicallResults2[5].toString(), actualRewarPerBlock)
        setApr(APR.toFixed(2))

        if(account){
            const calls3 = [
                {
                    address: address,
                    name: 'userInfo',
                    params: [account],
                },
                {
                    address: address,
                    name: 'pendingReward',
                    params: [account],
                },
            ]

            const multicallResults3 = await ethMulticall(stakingabi, calls3)
            const amountStaked = multicallResults3[0].amount.toString() / 10 ** multicallResults2[3]
            setAmountstaked(amountStaked)
            const rewardEarned = multicallResults3[1].toString() / 10 ** multicallResults2[2]
            setRewardearned(rewardEarned)

            const calls4 = [
                {
                    address: multicallResults[0].toString(),
                    name: 'allowance',
                    params:[account, address],
                },
            ]
            const multicallResults4 = await ethMulticall(TOKENABI, calls4)
            if(multicallResults4 > 0){
                setApproval(true)
            } else {
                setApproval(false)
            }
        }

  }, [account, chainId, active])


   const getPoolApr = async (
    stakingTokenPrice,
    rewardTokenPrice,
    totalStaked,
    tokenPerBlock,
  ) => {
    const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(10512000)
    // const s = tokenPerBlock === 0.5787037037037037 ? totalStaked-3000000 : totalStaked;
    const s = totalStaked;
    const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(s)
    const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
    return apr.isNaN() || !apr.isFinite() ? 0 : apr.toNumber()
  }


  const multicallHelper = async (abi,calls) => {
    const itf = new Interface(abi)
  
    const calldata = calls.map((call) => ({
      target: call.address.toLowerCase(),
      callData: itf.encodeFunctionData(call.name, call.params),
    }))

    return calldata 

  }

  const ethMulticall = async (TOKENABI, ethCalls) => {
    const web3 = new Web3(
      // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
      new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
    );

    const ethMulticall = new web3.eth.Contract(multicallabi, '0x1Ee38d535d541c55C9dae27B12edf090C608E6Fb');
    let aggregatedData = await multicallHelper(TOKENABI, ethCalls)
    const itf = new Interface(TOKENABI)

    let {returnData} = await ethMulticall.methods.aggregate(aggregatedData).call()
    const balanceTokens = returnData.map((call, i) => itf.decodeFunctionResult(ethCalls[i].name, call))
    return balanceTokens;
  }



  const delay = ms => new Promise(res => setTimeout(res, ms));




//   const withdrawfunds = async() => {
//       try{
//         const web3 = new Web3(library.provider)
//         const contract = new web3.eth.Contract(ABIPolygon, address);
//         await contract.methods.withdrawBNB().send({
//             from: account
//         })
//         toast.success("Funds withdrawn!", {
//             position: "top-right",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             });
//         setLoading(false) 
//       }catch(err){
//           console.log(err)
//           setLoading(false)
//           toast.error("Something went wrong", {
//               position: "top-right",
//               autoClose: 5000,
//               hideProgressBar: false,
//               closeOnClick: true,
//               pauseOnHover: true,
//               draggable: true,
//               progress: undefined,
//               });
//       }
      
//   }

  return (
    <div className="container">
    <div className="row" style={{marginTop:'10%', marginBottom:'5%'}}>
    <div className="card" style={{width:"100%", color:'white', background:'transparent', borderRadius:'22px', padding:'0px', boxShadow:'0 5.40728px 10.8146px rgb(71, 66, 255)'}}>
          <div className="card-header" style={{background:'#4742FF', borderRadius:'22px 22px 0px 0px'}}>
          <h5 style={{textAlign:'left', fontWeight:'bold', color:'white'}}>
                Staking Pool
                </h5>
          </div>                 <div className="card-body">
                <h5 style={{textAlign:'left'}}>
                    Stake {stakesymbol} , Earn {earnsymbol}
                </h5>
                <p style={{textAlign:'center', color:'white', background:'rgb(71, 66, 255)', border:'2px dashed #FFFFFF', borderRadius:'25px'}}>
                    {stakeAddress} , {earnAddress}
                </p>
                <div className="row">
                    <div className="col">
                       <h5> Staking APR: <span style={{background:'#4742FF', color:'white', borderRadius:'8px', padding:'5px'}}>{apr}%</span> </h5>
                    </div>
                    <div className="col">
                       <h5 style={{textAlign:'right'}}> TVL: <span style={{background:'#4742FF', color:'white', borderRadius:'8px', padding:'5px'}}>{tvl}</span> </h5>
                    </div>
                </div>
                <div className="row" style={{marginTop:'2%'}}>
                    <div className="col">
                        Start Block: <a href={`https://bscscan.com/block/${startBlock}`} target="_blank" style={{color:'orange'}} rel="noreferrer"><span style={{background:'#191D26', borderRadius:'8px', padding:'5px'}}>{startBlock}</span> </a>
                    </div>
                    <div className="col" style={{textAlign:'right'}}>
                        End Time: <a href={`https://bscscan.com/block/${endBlock}`} target="_blank" style={{color:'orange'}} rel="noreferrer"><span style={{background:'#191D26', borderRadius:'8px', padding:'5px'}}>{endBlock}</span> </a>
                    </div>
                </div>
                {account ?                 <div className="row" style={{marginTop:'2%'}}>
                    <div className="col">
                        Amount Staked: <span style={{background:'rgb(71, 66, 255)', borderRadius:'8px', padding:'5px', color:'white'}}> {amountstaked} </span>
                        {amountstaked !== 0 && amountstaked !== '' ? <button className="btn btn-primary" style={{border:"0px", background:'rgb(71, 66, 255)'}} onClick={unstakeAction} disbaled={loading}>Unstake</button> : null}
                    </div>
                    <div className="col" style={{textAlign:'right'}}>
                    Amount Earned: <span style={{background:'rgb(71, 66, 255)', borderRadius:'8px', padding:'5px', color:'white'}}>{rewardearned} </span>
                    </div>
                </div>: null}

                <div className="row">
                    <div className="col">
                        <input style={{width:'100%', marginTop:'10px', border:'1px solid rgb(71, 66, 255)', borderRadius:'25px', padding:'8px'}} onChange={(event) => setStakenow(event.target.value)} placeholder="Amount to Stake" type="number" />
                    </div>
                    <div className="col">
                        <button className="btn btn-primary" onClick={approval == true ? stakeAction : approveAction} disabled={loading} style={{width:'100%', marginTop:'8px', border:'0px', borderRadius:'25px', background:'rgb(71, 66, 255)'}}>
                         {approval == true ? "Stake" : "Approve"}
                        </button>
                    </div>

                </div>
            </div>
        </div>
    {/* TOAST AREA */}

    </div>
    </div>

  ); 
}

export default NFTSaleComponent;
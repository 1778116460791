import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Heading, Flex, Image, Text } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'




const NUMBER_OF_POOLS_VISIBLE = 12

const Pools = () => {


  const [selectedbg, setSelectedbg] = React.useState('#212129')
  const [cardcolor, setCardcolor] = React.useState('#212129')
  const [usraddr, setUsraddr] = React.useState('0x0')
  const [usraddr2, setUsraddr2] = React.useState('0x7fe8dac51394157811c71bbf74c133a224a9ff44')
  const [link, setLink] = React.useState('https://xx.alphalabz.net/swap?outputCurrency=0x7fe8dac51394157811c71bbf74c133a224a9ff44&dark=dark')
  const [slippage, setSlippage] = React.useState('0.5')
  const [dark, setDark] = React.useState('dark')

  const changeLink = async() => {
    console.log(selectedbg)
    // @ts-ignore
    const slipapge = slippage * 100
    const color = selectedbg.replace('#','');
    let inputCurrency;
    let outputCurrency;
    if(usraddr==='0x0'){
      inputCurrency = 'ETH'
    } else {
      inputCurrency = usraddr;
    }
    if(usraddr2==='0x0'){
      outputCurrency = 'ETH'
    } else {
      outputCurrency = usraddr2;
    }

    const newLink = `https://xx.alphalabz.net/swap?inputCurrency=${inputCurrency}&outputCurrency=${outputCurrency}&bg=${color}&slippage=${slipapge}&card=${cardcolor.replace('#','')}&dark=${dark}`
    setLink(newLink)
    
  }

  const changeType = async(val) => {
    
    if(val === 'dark'){
      setDark('dark')
      setCardcolor('#212129')
      setSelectedbg('#212129')
    } else {
      setDark('light')
      setCardcolor('#ffffff')
      setSelectedbg('#ffffff')
    }
  }


  return (
    <>
      {/* <p style={{color:'#FC7442'}}>LiquidFrame</p> */}
      <div>
        <br /> <br />
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Heading className="coolgradient2" as="h1" scale="xl" color="white" mb="24px" style={{textAlign:'center', fontWeight:"100", marginTop:"5%",  borderRadius:'20px'}}>
            Launch your own decentralized exchange
            <p> on your site </p>
            </Heading>

          </Flex>
        </Flex>
      </div>
      <Page>
        <div className="row">
            <div className="col-12 col-md-8" style={{marginTop:'3%'}}>
              <div style={{background:`#02023A`, boxShadow:'0px 50px 132px rgba(0, 0, 0, 0.35)',  width:'100%', margin:'0 auto', display:'block', border:'1px solid #0167FF', borderRadius:'25px', marginTop:'9%'}}>
                
                <div className="row" style={{padding:'3%'}}>
                  <div className="row" style={{padding:'1%'}}>
                    <div className="col-6">
                      <div id="emailHelp" className="form-text">Choose Theme</div>
                        <select className="form-select" aria-label="Default select example" style={{background:'transparent', color:'white'}} onChange={(event) => changeType(event.target.value)}>
                          <option selected="dark" value="dark">Dark</option>
                          <option value="light">Light</option>
                        </select>

                      </div>
                      <div className="col-6">
                      <div id="emailHelp" className="form-text">Custom BG?</div>
                        <input type="color" value={selectedbg} onChange={(event) => setSelectedbg(event.target.value)} className="form-control" id="exampleFormControlInput1"  style={{background:'transparent', height:'38px'}} placeholder="name@example.com" />
                      </div>
                      <div className="col-6" style={{marginTop:'3%'}}>
                      <div id="emailHelp" className="form-text">From Token Address (use 0x0 for BNB)</div>
                        <input type="text" onChange={(event) => setUsraddr(event.target.value)}  className="form-control" id="exampleFormControlInput1"  style={{background:'transparent', height:'38px', color:'white'}} placeholder="0x000000" />
                      </div>
                      <div className="col-6" style={{marginTop:'3%'}}>
                      <div id="emailHelp" className="form-text">To Token Address (use 0x0 for BNB)</div>
                        <input type="text" onChange={(event) => setUsraddr2(event.target.value)}  className="form-control" id="exampleFormControlInput1"  style={{background:'transparent', height:'38px', color:'white'}} placeholder="0x000000" />
                      </div>
                      <div className="col-6" style={{marginTop:'3%'}}>
                      <div id="emailHelp" className="form-text">Default Slippage</div>
                        <input type="text" onChange={(event) => setSlippage(event.target.value)}  className="form-control" id="exampleFormControlInput1"  style={{background:'transparent', height:'38px', color:'white'}} placeholder="0.5" />
                      </div>
                      <div className="col-6" style={{marginTop:'3%'}}>
                      <div id="emailHelp" className="form-text">Custom Card Color</div>
                        <input type="color" value={cardcolor} onChange={(event) => setCardcolor(event.target.value)} className="form-control" id="exampleFormControlInput1"  style={{background:'transparent', height:'38px'}} placeholder="name@example.com" />
                      </div>
                      <div className="col-12" style={{marginTop:"3%"}}>
                        <button type="button" className="btn btn-primary" style={{border:'0px',width:"110px", margin:'0 auto', display:'block', background:'linear-gradient(95.79deg, #0167FF 4.35%, #FA06FF 100%)'}} onClick={changeLink} >Build</button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4" style={{marginTop:'3%'}}>
              <iframe title="dex" id="rubic-widget-iframe" scrolling="no" style={{width:'350px', height:'525px', border:'1px solid #0167FF', borderRadius:'25px', boxShadow: "rgba(0, 0, 0, 0.1) 3px 3px 10px 4px", display: "block", margin:'0 auto'}} src={link} />
            </div>
        </div>

        
      <div style={{background:`#011035`, backgroundSize:'cover', width:'100%', margin:'0 auto', display:'block', border:'1px solid #0167FF', borderRadius:'25px'}}>

                  

                  <div className="row" style={{marginTop:'3%'}}>
                    <div className="col" style={{width:'100%', margin:'0 auto'}}>
                  
                  <div className="row" style={{padding:'3%', color:'white'}}>
                  <h4 style={{fontSize:'1.5rem'}}>React.js</h4>
                  <div style={{background:'transparent', padding:'15px', border:'1.2px solid #FA06FF', marginTop:'1%'}}>
                      <code style={{color:'rgb(3, 149, 219)'}}>
                      &lt;iframe scrolling=&quot;no&quot; height=&quot;525&quot; width=&quot;350&quot; style=&#123;&#123;width:&quot;350px&quot;,height:&quot;525px&quot;, border: &quot;none&quot;, borderRadius: &quot;19px&quot;, boxShadow: &quot;rgba(0, 0, 0, 0.1) 3px 3px 10px 4px&quot;, display: &quot;block&quot;, margin:&quot;0 auto&quot;&#125;&#125; src=&quot;{link}&quot; /&gt;

                      </code>
                    </div>
                    
                    <h4 style={{fontSize:'1.5rem', marginTop:'3%'}}>HTML & Other sites [inline css]</h4>
                    
                    <div style={{background:'transparent', padding:'15px', border:'1.2px solid #FA06FF', marginTop:'1%'}}>
                      <code style={{color:'rgb(3, 149, 219)'}}>
                      &lt;iframe scrolling=&quot;no&quot; height=&quot;525&quot; width=&quot;350&quot; style=&quot;width:350px; border: none; border-radius: 19px; box-shadow: rgba(0, 0, 0, 0.1) 3px 3px 10px 4px; display: block; margin:0 auto&quot; src=&quot;{link}&quot; /&gt;

                      </code>
                    </div>
                  </div>
                  </div>
                  </div>
                  </div>
      </Page>
    </>
  )
}

export default Pools

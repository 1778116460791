import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Heading, Flex, Image, Text } from '@pancakeswap/uikit'
import Page from 'components/Layout/Page'
import {
  useWeb3React,
  
} from "@web3-react/core";
import axios from 'axios'
import tokebabi from 'config/abi/erc20.json'
import Web3 from 'web3'
import ConnectWalletButton from '../../components/ConnectWalletButton'


const Pools = () => {

  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

  const [loading, setLoading] = React.useState(false)
  const [issubmitted, setIssubmitted] = React.useState(false)
  const [information, setInfo] = React.useState('')

  const [outputai, setOutputai] = React.useState('')

  const ethercontract = '0x5d259E3FbcAdef1abfeA5582e456Bc7F0aebC2a1'
  const bsccontract = '0xA991fA7Fa94DFE2E59d58954BCe0F0a8E082710f'

  const submitInfo = async() => {
      try{
          // https://polar-refuge-71486.herokuapp.com/   new https://radiant-chamber-16572.herokuapp.com/
          if(account){
              setLoading(true)

              // check account balance

              const web3 = new Web3(
                // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
                new Web3.providers.HttpProvider("https://bsc-dataseed.binance.org/")
              );

              const web32 = new Web3(
                // Replace YOUR-PROJECT-ID with a Project ID from your Infura Dashboard
                new Web3.providers.HttpProvider("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161")
              );
          
              const tokeneth = new web32.eth.Contract(tokebabi, ethercontract);
              const tokenbsc = new web3.eth.Contract(tokebabi, bsccontract);

              const balance1 = await tokeneth.methods.balanceOf(account).call()
              const balance2 = await tokenbsc.methods.balanceOf(account).call()

              const total = parseFloat(balance1) + parseFloat(balance2)
              const onepc = 100000 * 1e18

              if(total >= onepc){
                const key = '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'
                const config = {
                  
                  headers: {    'Authorization': `Bearer ${key}`
                 }
                };
                if(information !== '' && information.length > 25){
                  const bodyParameters = {
                    "model": "text-davinci-003",
                    "prompt": `Validate this business idea: ${information}`,
                    "max_tokens": 80,
                    "temperature": 0,
                    "top_p": 1,
                    "n": 1              
                  };
    
                  const req = await axios.post('https://api.openai.com/v1/completions', 
                  {
                    "model": "text-davinci-003",
                    "prompt": `Validate this business idea: ${information}`,
                    "max_tokens": 80,
                    "temperature": 0,
                    "top_p": 1,
                    "n": 1
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${key}`}
                  }, 
                  )
                  console.log('req is ', req.data)
                  setOutputai(req.data.choices[0].text)
                  setLoading(false)
                }
                else{
                  alert('Minimum number of characters: 25')
                  setLoading(false)
  
                }
              }
              else{
                alert('You must hold at least 1% of the entire supply')
                setLoading(false)
              }






          } else {
            alert('Connec to an Account')
          }



      }catch(err){
        console.log('err is ', err)
          alert('an errror occured')
          setLoading(false)
      }

  }


  return (
    <>
      {/* <p style={{color:'#FC7442'}}>LiquidFrame</p> */}
      <div>
        <br /> <br />
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            {/* <Heading as="h1" scale="xxl" color="white" mb="24px" style={{textAlign:'center', fontWeight:"100",   borderRadius:'20px'}}>
             DEX IFRAME 
            </Heading> */}
                  <br /> <br />
              <h2 className="coolgradient" style={{textAlign:'center', fontSize:'37px',  marginTop:"5%", width:'100%', maxWidth:'511px', margin:'0 auto', display:'block', fontWeight:'900'}}>
              Alpha Intelligence Business Validator
                  </h2>
                  <div className="container">
                  <div className="row">
              <div className="col-12">
                  <div className="card" style={{background:'transparent',border:'0px', padding:'15px'}}>
                      <div className="card-body">
                          <br />
                          <p style={{color:'white', fontSize:'23px'}}>
                          Share a business idea with the AI and get it validated. 
                          </p>
                          <br />
                          <div>
      
<textarea style={{width:"100%",color:'white', minHeight:'150px', background:'transparent', border:'1px solid #0167FF', borderRadius:'15px', padding:'8px', filter:'drop-shadow(0px 25px 60px rgba(1, 103, 255, 0.09))'}} placeholder="Describe your business idea. E.g: I am about to launch a cryptocurrency business where the utility token will be used as a governance of a DAO." onChange={(event) => setInfo(event.target.value)} />
                  <div className="row">
                      <div className="col">
                                        {active ? 
                                        
                                        issubmitted === false ? <button className="btn btn-text" type="button" onClick={submitInfo}  disabled={loading} style={{background:'linear-gradient(141.82deg, #0167FF 18.89%, #FA06FF 79.14%)', border:'0px', borderRadius:'25px', color:'white', width:'100%', maxWidth:'212px', fontSize:'16px', margin:'0 auto', display:'block', marginTop:"3%"}}>
                                        Submit to AI
                                    </button> : <p style={{color:'green', marginTop:"2%", textAlign:"center"}}>Information Submitted Successfully</p>
                                        : 
                                        <> <br />
                                        <ConnectWalletButton width="100%"  />
                                        </>
                                      }
                                      
                                      {outputai !== '' ? 
                                      
                                      <div className='card' style={{ width:'100%', maxWidth:'500px', margin:'0 auto', display:'block', marginTop:'1rem', padding:'10px', background:'transparent', color:'white',  border:'1px solid #0167FF', borderRadius:'15px'}}>
                                        <p>
                                          {outputai}                                        </p>
                                      </div>
                                      : null}
                                      
                                 
                                        </div>
                  </div>
                                    </div>
                        </div>
                        
                </div>
            </div>

        </div>
     

      </div>

          </Flex>
        </Flex>
      </div>
      {/* <Page>
        
      </Page> */}
    </>
  )
}

export default Pools

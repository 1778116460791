import { MenuItemsType, DropdownMenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [

  {
    label: 'Staking',
    icon: 'Earn',
    href: '/pools',
    items:[
      {
              label: 'Pools',
              href: '/pools',
      },
      {
        label: 'Finished',
        href:'/pools/history'
      }
    ]
  },
  {
    label: t('Referral'),
    href: '/referral',
    icon: 'Earn',
    items: [
      {
        label: t('Referral'),
        href: '/referral',
      }
    ],
  },
  {
    label: t('Swap'),
    href: '/swap',
    icon: 'Swap',
    items: [
      {
        label: t('Dex Aggregator'),
        href: '/swap',
      }
    ],
  },
  // {
  //   label: t('NFT'),
  //   href: `${nftsBaseUrl}`,
  //   icon: 'Nft',
  //   items: [
  //     {
  //       label: t('Overview'),
  //       href: `${nftsBaseUrl}`,
  //       status: {
  //         text: t('Live'),
  //         color: 'failure',
  //       },
  //     },
  //     {
  //       label: t('Collections'),
  //       href: `${nftsBaseUrl}/collections`,
  //     },
  //   ],
  // },
  {
    label: 'Creator Tools',
    href: '/Post2Earn',
    icon: 'More',
    // hideSubNav: false,
    items: [
      {
        label: t('Make a Dex'),
        href: '/make-a-dex',
      },
      {
        label: t('Post2Earn'),
        href: '/Post2Earn',
      },
      {
        label: t('NFT Launchpad'),
        href: 'https://mintstarter.app',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        type: DropdownMenuItemType.DIVIDER,
      },
      {
        label: t('Create a Token'),
        href: '/token-creator',
      },
      {
        label: t('Create Staking Pool'),
        href: '/entry-staking',
      },
      // {
      //   type: DropdownMenuItemType.DIVIDER,
      // },
      // {
      //   label: t('Docs'),
      //   href: 'https://docs.pancakeswap.finance',
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      // },
    ],
  },
]

export default config
